.file-logos-type-background{
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    font-weight: 900;
    font-size: 14px;
    color: #fff;

    .file-logos-type-text{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.file-logos-type-1{
    background-color: #f26b6b;
}

.file-logos-type-2,
.file-logos-type-3{
    background-color: #6640d2;
}

.file-logos-type-5{
    background-color: #005397 ;
}

.file-logos-type-6{
    background-color: #1D6F42;
}

.file-logos-type-7,
.file-logos-type-4{
    background-color: #00A4EF;
}

@primary-color: #1DA57A;