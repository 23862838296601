@import "~antd/dist/antd.less";
@import "./styles/color.less";
@import "./styles/common.less";
@import "./styles/salaryManageStyles.less";
@import "./styles/generalStyles.less";

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

.app-heading-1 {
  font-size: 32px;
  font-weight: 700;
}

.app-heading-2 {
  font-size: 24px;
  font-weight: 700;
}

.app-heading-3 {
  font-size: 20px;
  font-weight: 700;
}

.app-heading-4 {
  font-size: 18px;
  font-weight: 700;
}

.app-heading-5 {
  font-size: 16px;
  font-weight: 700;
}

.app-heading-6 {
  font-size: 14px;
  font-weight: 700;
}

.app-heading-7 {
  font-size: 12px;
  font-weight: 700;
}

.title-gray {
  margin: 10px 0;
  font-weight: 400;
  color: @color-Manatee;
}

.title-gray-1 {
  font-size: 24px;
  .title-gray();
}
.title-gray-2 {
  font-size: 20px;
  .title-gray();
}

.title-gray-3 {
  font-size: 18px;
  .title-gray();
}
.title-gray-4 {
  font-size: 16px;
  .title-gray();
}
.title-gray-5 {
  font-size: 14px;
  .title-gray();
}
.title-gray-6 {
  font-size: 12px;
  .title-gray();
}
.title-gray-7 {
  font-size: 10px;
  .title-gray();
}

.app-label-1 {
  color: @color-ManateeLite;
  font-size: 16px;
  font-weight: 600;
}
.app-label-2 {
  color: @color-ManateeLite;
  font-size: 16px;
  font-weight: 500;
}
.app-label-3 {
  color: @color-ManateeLite;
  font-size: 14px;
}
.app-label-4 {
  color: @color-ManateeLite;
  font-size: 12px;
}
.app-label-value-1 {
  font-size: 16px;
  font-weight: 600;
  margin: 12px 0px;
}

.app-input-field-container-1 {
  padding: 16px 0px;
}

.app-input-field-container-2 {
  padding: 8px 0px;
}

.app-label-1 {
  color: #9898a5;
  font-size: 16px;
  font-weight: 700;
}

.app-input-container {
  margin: 32px 0px;
}

.app-input-container-1 {
  margin: 24px 0px;
}

.app-input-container-2 {
  margin: 16px 0px;
}

.app-white-card {
  background-color: #fff;
  padding: 45px;
  border-radius: 10px;
}

.app-flex-container-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.submit-button,
.submit-button:active,
.submit-button:focus,
.submit-button:hover {
  background: #40d2ac 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: none;
  height: 40px;
  color: #ffffff;
  margin: 0 20px;
}

.edit-button,
.edit-button:active,
.edit-button:focus,
.edit-button:hover {
  background-color: #fff7ec;
  border-radius: 5px;
  color: #fea101;
  height: 40px;
  border: none;
  margin-right: 10px;
}

.cancel-button,
.cancel-button:active,
.cancel-button:focus,
.cancel-button:hover {
  background-color: #fff;
  border-radius: 3px;
  border-color: #cfcfcf;
  color: #99999f;
  height: 40px;
  margin-right: 10px;
}

.ant-input[disabled],
.ant-input-number-disabled {
  background-color: #fff;
  color: #232326;
}

.general-input-field,
.general-input-field:active,
.general-input-field:hover,
.general-input-field:focus {
  border: none;
  background-color: #fff;
  border-bottom: 1px solid #eaeaee;
  border-radius: 0px;
  box-shadow: none;
  text-align: left;
  color: #232326;
  font-weight: 600;
  font-size: 15px;
  padding: 2px 2px;
}

.general-input-field-number {
  border: none;
  width: 100%;
  background-color: #fff;
  border-radius: 0px;
  box-shadow: none;
  text-align: left;
  color: #232326;
  font-weight: 600;
  font-size: 15px;
}
.ant-input-number-input {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  text-align: left;
  color: #232326;
  font-weight: 600;
  font-size: 15px;
  padding: 12px 2px;
}

.ant-input-number-handler-wrap {
  visibility: hidden;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #232326;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #fff;
  color: #232326;
}

.ant-select:focus,
.ant-select:hover,
.ant-select:active {
  box-shadow: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  color: #232326;
  border: none;
  border-bottom: 1px solid #eaeaee;
  border-radius: 0px;
  box-shadow: none;
  padding: 0px;
}

.general-select-field,
.general-select-field:hover,
.general-select-field:active,
.general-select-field:focus {
  border: none;
  box-shadow: none;
  text-align: left;
  color: #232326;
  font-weight: 600;
  font-size: 15px;
  padding: 12px 0px;
}

.ant-picker-input {
  .input[disabled] {
    color: #232326;
  }
}

.ant-picker,
.ant-picker-large,
.ant-picker.ant-picker-disabled {
  background-color: #fff;
  color: #232326;
  border: none;
  border-bottom: 1px solid #eaeaee;
  border-radius: 0px;
  box-shadow: none;
  padding: 12px 0px;
}

.ant-picker-input-placeholder .input {
  color: #232326;
}

.border-buttom {
  border-bottom: 2px solid #f5f5f5;
}

.input-field {
  width: 250px;
  padding: 4.5px 76px 4.5px 10px;
  border-radius: 3px;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0.5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  opacity: 1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  opacity: 1;
}

.skip-button {
  height: 40px;
  letter-spacing: 0px;
  color: #000;
  margin-left: 20px;
}

.full-width {
  width: 100%;
}

.full-min-height {
  min-height: 100%;
}

* {
  scrollbar-width: none;
}

.app-main-content-container {
  width: 95%;
  border-radius: 10px;
  margin: 0px auto;
  margin-top: 25px;
}

.app-breadcrum-container {
  width: 95%;
  margin: 0px auto;
  margin-bottom: 15px;

  .app-breadcrum {
    font-size: 16px;
    color: #90919b;
    font-weight: 600;

    .app-breadcrum-active {
      color: #405cd2;
    }
  }
}

.document-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-display {
  width: 100%;
  height: auto;
}

.pdf-document-action {
  display: flex;
  align-items: center;
  justify-content: space-around;
  p {
    font-weight: 500;
  }
}
.ctc-input {
  background-color: #f5f5f5;
  padding: 12px;
  margin-bottom: 36px;
  border: none;
  width: 100%;
  border-radius: 2px;
  font-size: 16px;
}

.employee-input {
  background-color: #f5f5f5;
  padding: 12px;
  margin-bottom: 24px;
  border: none;
  width: 100%;
  border-radius: 2px;
  font-size: 16px;
  border-bottom: 0.1px solid;
}

.general-ant-field-label {
  margin-top: 16px;
  color: #9898a5;
  font-size: 16px;
}

.general-ant-field-input {
  font-weight: 500;
  padding: 6px 0px;
  background-color: @color-White;
  border-radius: 0px;
  text-align: left;
  color: @color-Shark;
  margin-bottom: 10px;
  border: none;
  box-shadow: none;
  border-bottom: 0.1px solid #eaeaee;
  font-size: 16px;
}

.general-ant-field-input:active,
.general-ant-field-input:hover,
.general-ant-field-input:focus {
  border-bottom: 2px solid #405cd2;
}

.general-ant-field-select {
  font-weight: 500;
  padding: 0px 2px;
  margin-bottom: 10px;
  background-color: @color-White;
  border-radius: 0px;
  text-align: left;
  color: @color-Shark;
  border: none;
  box-shadow: none;
  border-bottom: 0.1px solid #eaeaee;
  font-size: 16px;
}

.general-ant-field-select:active,
.general-ant-field-select:focus,
.general-ant-field-select:hover {
  border-bottom: 2px solid #405cd2;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: blue;
  font-size: 14px;
  font-weight: 500;
}

.ant-menu-item a,
.ant-menu-item a:hover {
  font-size: 12px;
  font-weight: 400;
}

.ant-menu-item {
  width: 200;
}

.org-data-container {
  .fields-heading {
    margin-top: 16px;
    font-size: 1rem;
    color: #9898a5;
  }

  .input-field {
    border-radius: 0.2rem;
    outline: none;
    height: 50px;
  }
  .phone-number-input {
    width: 65%;
    display: inline-block;
    .county-code-select {
      width: 15%;
      height: 100%;
      display: inline-block;
      margin-right: 5%;
    }
  }
}

.organisation-details-container {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 2px;
  height: 100%;
  width: 93%;
  margin-left: 30px;
}

.select-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.individual-structure {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 10px 0;

  .break-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.19;
    text-align: left;
    color: #5f5f65;
  }

  .break-value {
    font-size: 16px;
    line-height: 1.19;
    text-align: left;
    color: #5f5f65;
  }

  .break-label-earning {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.19;
    text-align: left;
    color: #40d2ac;
  }

  .break-value-earning {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.19;
    text-align: left;
    color: #40d2ac;
  }
  .break-label-deduction {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.19;
    text-align: left;
    color: #f26b6b;
  }

  .break-value-deduction {
    font-size: 16px;
    line-height: 1.19;
    text-align: left;
    color: #f26b6b;
  }
}
.individual-structure-takehome {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 10px 0;

  .break-label {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.19;
    text-align: left;
    color: #1777e5;
  }

  .break-value {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.19;
    text-align: left;
    color: #1777e5;
  }
}

.accepted {
  font-weight: 600;
  color: #1da57a;
  font-size: 12px;
}

.pending {
  font-weight: 600;
  color: #fea101;
  font-size: 12px;
}

.paper-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .paper-heading {
    font-size: 20px;
    font-weight: 600;
  }
  .paper-heading-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex-direction-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.background-white {
  background-color: #fff;
}

.ant-table-thead > tr > th {
  color: #bbbbbf;
  background: none;
}

.success-text {
  color: #15cbac;
}

.danger-text {
  color: #f26b6b;
}

.warning-text {
  color: #f3a02e;
}

.info-text {
  color: #1777e5;
}

.paper-wrapper {
  background-color: @color-White;
  padding: 30px;
  border-radius: 2px;
  margin-bottom: 20px;

  .paper-header-container {
    .display-center-content-space-between();

    .paper-heading {
      font-size: 25px;
      font-weight: 500;
    }
    .paper-heading-action {
      .display-center-content-space-between();
    }
  }
}

.nothing-height {
  height: 100px;
}

.payout-state-0-image {
  width: 100%;
  max-width: 1000px;
}

.mt-3 {
  margin-top: 10px;
}

.document-container {
  .display-center-content-space-between();
  margin: 12px 0;
  .file-typeHeader {
    width: 50px;
    height: 50px;
    margin: 0 10px 0;
    font-weight: 400;
    border-radius: 2px;
    background-color: @color-FairPink;
    color: @color-MediumCarmine;
    .display-align-center-justify-content-center();
  }
  span {
    text-align: left;
    font-weight: 500;
    color: @color-MidGray;
  }
  .date-contianer {
    line-height: 2.14;
    font-size: 12px;
    letter-spacing: normal;
    text-align: left;
    color: @color-FrenchGray;
  }
  .verifiection-text {
    .display-align-center();
    flex-direction: row;
    letter-spacing: normal;
    font-size: 14px;
    text-align: left;
    float: right;
  }
}

.document-list {
  margin: 10px 0px;
  .document-container {
    .display-center-content-space-between();
    margin: 12px 0;
    .file-typeHeader {
      width: 50px;
      height: 50px;
      margin: 0 10px 0;
      font-weight: 400;
      border-radius: 2px;
      background-color: @color-FairPink;
      color: @color-MediumCarmine;
      .display-center-content-space-between();
    }
    span {
      text-align: left;
      font-weight: 500;
      color: @color-MidGray;
    }
    .date-contianer {
      line-height: 2.14;
      font-size: 12px;
      letter-spacing: normal;
      text-align: left;
      color: @color-FrenchGray;
    }
    .verifiection-text {
      .display-align-center();
      flex-direction: row;
      letter-spacing: normal;
      font-size: 14px;
      text-align: left;
      float: right;
    }
  }
}

.dragger-container {
  height: 65%;
}

.dragger {
  margin-top: 5px;
  padding: 0 10px;
  .dragger-info-container {
    height: 5.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .uploadLogo {
      color: #9898a5;
      font-size: 40px;
    }
    .ant-upload-text {
      margin-top: 10px;
      margin-left: 16px;
      color: #90919b;
      text-align: left;
    }
  }
}

.document-group {
  margin: 10px 0;
  .document-header {
    .display-center-content-space-between();
    padding: 10px;
    padding-right: 0px;
    .document-header-button-and-name-container {
      .display-align-center();
      .document-action-container {
        .display-center-content-space-between();
      }
    }
    p {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 500;
    }
    .allowance-amount {
      font-weight: 500;
      color: @color-OrangePeel;
    }
  }
}

.box-danger {
  box-shadow:  rgba(255, 121, 97, 0.782) 0px 0px 3px;
}

@primary-color: #1DA57A;