.logo {
  height: 50px;
  position : sticky;
  padding: 0  24px;
  top: 0;
  z-index: 50;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .mool-logo {
    height: auto;
    width: 6rem;
  }

  .trigger {
    padding: 8px 30px;
    font-size: 15px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #73737b;
  }
}

.display-container {
  min-height: 100vh;
  width: 100%;

  .sider{
    position: sticky;
    background: #fff;
    left: 0;
    top: 75px;
    z-index: 50;
  }

  .site-layout {
    .site-layout-header {
      background: #f0f2f5;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      width: 95%;
      margin: 0px auto;

      .header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .logout-form {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-around;
        .name-container {
          margin: 0 10px;
          @media (max-width: 600px) {
            display: none;
          }
          
        }
      }
    }
  }
}

.sidebar-icon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

@primary-color: #1DA57A;