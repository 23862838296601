.newCampaignContainer {
  height: auto;
  .newCampaignHeader {
    .newCampaignHeading {
      font-size: 25px;
      font-weight: 600;
      text-align: left;
      color: #232326;
    }
  }
  .initialInformation {
    margin-top: 25px;
    .information-heading {
      font-size: 22px;
      font-weight: 600;
      text-align: left;
      color: #405cd2;
    }
    .information-content {
      .description-box-container {
        margin-top: 5px;
        height: 7.5rem;
        padding: 2px;
        border: 1px solid #9898a5;
        border-radius: 5px;
        .description-box-input {
          font-size: 14px;
          font-weight: 500;
          color: #232326;
        }
      }
      .checkbox-container {
        display: flex;
        flex-direction: column;
        margin: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #707070;
      }
    }
  }
  .buttonContainer{
      margin-top: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
  }
}

@primary-color: #1DA57A;