
    .org-list-name-container{
        display: flex;
        align-items: center;

        .org-list-name-and-pan{

            .org-list-name{
                font-size: 17px;
                font-weight: 800;
                margin-bottom: 6px;
            }

            .org-list-pan{
                font-size: 14px;
                font-weight: 500;
                color: #93979b;
            }
        }

        .org-list-logo{
            width: 45px;
            height: 45px;
            border-radius: 50px;
            background-color: #fff;
            margin-right: 24px;
        }

        .ca-list-logo{
            width: 45px;
            height: 45px;
            border-radius: 50px;
            
            margin-right: 24px;
        }

        .ca-list-logo-color{
            background-color: #43de90;
        }

        .org-list-logo-color{
            background-color: #fff1de;
        }
    }

    .date-of-joining{
        color: #93979b;
        text-align: 14px;
        font-weight: 500;
    }

    .poc-info{
        color: #000;
    }

   

@primary-color: #1DA57A;