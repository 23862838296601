.status{
    padding: 8px 16px;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    border-radius: 7.5px;
    font-weight: 500;
    min-width: 140px;
    .status-img{
        margin-right: 8px;
        height: 16px;
        width: 16px;
    }
}

.status-1{
    background-color: #fff1de;
    color: #feba01;
}

.status-2{
    background-color: #e4fff8;
    color: #40d2ac;
}

.status-3{
    background-color: #ffeeee;
    color: #f26b6b;
}

@primary-color: #1DA57A;