.button-lib{
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    margin: 0px;
    font-weight: 400;
    letter-spacing: 0.75px;
    font-size: 15px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.button-type-2{
    background-color: #fff1de;
    color: #feba01;
}

.button-type-1{
    background-color: #40d2ac;
    color : #fff;
}
.button-type-1:hover{
    background-color: #39ebbb;
}
.button-type-1:active{
    background-color: #29cea2;
}

.button-type-3{
    background-color: #f7d8d8;
    color: #f26b6b;
}

.button-type-4{  
    background-color: transparent;
    color: #fff;
}

.button-type-5{
    background-color :transparent;
    color: #bcbdc8;
}
.button-type-5:hover,
.button-type-5:active{
    color: #959596;
}

.button-type-6{
    background-color: transparent;
    color : #40d2ac;
}

.button-type-6:hover{
    background-color: #40d2ac;
    color: #fff;
}

.button-type-7{
    background-color :transparent;
    color: #bcbdc8;
    border: #bcbdc8 1px solid;
    font-weight: 500;
}

.button-type-7:hover,
.button-type-7:active{
    background-color: #e8ecff;
    color: #405cd2;
    border: #405cd2 1px solid;
}

.button-type-8{
    background-color :transparent;
    color: #feba01;
    font-weight: 400;
}

.button-type-8:hover,
.button-type-8:active{
    background-color: #ffd86c;
    color: #fff;
    font-weight: 400;
}

.button-type-9 {
    background-color: #405cd2;
    color: #fff;
  }
  .button-type-9:hover {
    background-color: #3047a3;
  }

@primary-color: #1DA57A;