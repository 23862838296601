.border-bottom {
  border-bottom: solid 0.5px #eaeaee;
}
.align {
  text-align: left;
  color: #232326;
  font-weight: 600;
  font-size: 15px;
  padding: 8px 2px;
}

.org-details-column {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .organisation-basic-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 24px;

    .organisation-profile-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .organisation-logo-container {
        margin-right: 15px;

        .organisation-logo {
          background-color: #fea101;
          border-radius: 50%;
        }
      }

      .organisation-name-container {
        margin-right: 10px;
        .organisation-name-displayname {
          font-size: 25px;
          font-weight: 600;
        }
        .employee-number {
          font-size: 16px;
          font-weight: 400;
          color: #90919b;
        }
      }
    }

    .password-container {
      .button-password {
        margin-left: 35px;
        color: #9898a5;
      }
    }
  }

  .information-section {
    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px;
      .information-category-heading {
        margin-top: 15px;
        line-height: 1.28;
        color: #444444;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
      }
      .edit-card-button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 20px;
        color: #fea101;
      }
    }

    .employee-info {
      padding: 4px;
      .fields-heading {
        margin-top: 12px;
        font-size: 1.1rem;
        text-align: left;
        color: #90919b;
      }

      .profile-data-display {
        border-radius: 0.2rem;
        .align();
        .border-bottom();
      }
      .phone-number-holder {
        .phone-country-code {
          width: 20%;
          display: inline-block;
          .align();
          .border-bottom();
        }
        .phone-number {
          width: 78%;
          margin-left: 5px;
          .align();
          display: inline-block;
          .border-bottom();
        }
      }
    }
  }
  .viewLog-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    width: 93%;
    height: auto;
    margin-left: 30px;
    margin-top: 25px;
    margin-bottom: 12px;
    padding: 1.2rem;
    border-radius: 5px;
    .view-log-data-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .issue-number {
        margin: 5px 10px;
        font-size: 20px;
        font-weight: 900;
      }
      .issue-type {
        margin: 5px 10px;
        font-size: 14px;
        color: #9898a5;
      }
    }
    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fea101;
      .button-text {
        margin-top: 3px;
        margin-left: 3px;
        font-size: 12px;
      }
    }
  }
}
.address-subscription {
  .address-edit {
    @media (max-width: 1800px) {
      margin-top: 190px;
    }
  }
  .subscription-type-column {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    width: 100%;
    .numCard-container {
      width: auto;
      height: 180px;
      display: flex;
      flex-wrap: wrap;
      .basic-numCard-container-left {
        display: flex;
        justify-content: space-between;
        width: auto;
        min-width: 45%;
        height: 100%;
        margin-right: 30px;
        margin-bottom: 20px;
        background-color: #fff;
        padding: 1.2rem 3rem;
        line-height: 1.7;
        border-radius: 3px;
        .subscription-type {
          .subscription-type-heading {
            margin: 5px;
            color: #90919b;
            font-size: 15px;
            font-weight: 400;
          }
          .subscription-type-label {
            margin: 5px;
            color: #2d2e30;
            font-size: 20px;
            font-weight: 700;
          }
        }
        .edit-card-button {
          color: #fea101;
        }
        @media (max-width: 1800px) {
          width: 100%;
        }
      }
      .basic-numCard-container-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: auto;
        min-width: 45%;
        height: 100%;
        margin: 0 30px 0px 0;
        background-color: #fff;
        padding: 1.2rem 3rem;
        border-radius: 5px;
        .icon-holder {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 80px;
          width: 80px;
          background-color: #ffe9d6;
          border-radius: 50%;
          margin-left: 5px;
        }
        .revenue-data-holder {
          .revenue-heading {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            text-align: left;
            color: #90919b;
          }
          .revenue-amount {
            font-size: 25px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            text-align: left;
            color: #2d2e30;
          }
        }
        @media (max-width: 1800px) {
          width: 100%;
        }
      }
    }
    .transaction-history-container {
      width: 100%;
      height: 100%;
      background-color: #fff;
      padding: 1.5rem;
      border-radius: 3px;
      margin-top: 25px;
      .transaction-history-heading {
        font-size: 20px;
        font-weight: 700;
        color: #444444;
        margin-left: 5px;
        padding: 5px;
      }
      .payment-table {
        padding: 5px;
        margin-top: 10px;
      }
    }
  }
}
.subscription-modal {
  padding: 10px;
  margin-bottom: 6rem;
  border-radius: 10px;
  .modal-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .modal-section-heading {
      color: #151b30;
      font-size: 20px;
      font-weight: 700;
    }
    .modal-close {
      display: flex;
    }
  }
  .modal-body-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .subscription-type {
      .subscription-type-heading {
        margin-top: 12px;
        font-size: 15px;
        text-align: left;
        color: #9898a5;
      }
    }
    .subscription-pricing-info {
      width: "90%";
      .subscription-pricing-heading {
        margin-top: 12px;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        color: #9898a5;
      }
      .subscription-pricing-display {
        border-radius: 0.2rem;
        .align();
        .border-bottom();
      }
    }
    .subscription-button {
      text-align: right;
    }
  }
}

.fields-heading {
  margin-top: 12px;
  font-size: 1.1rem;
  text-align: left;
  color: #90919b;
}

.profile-data-display {
  border-radius: 0.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  .align();
  .border-bottom();
}

.originality-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 3px;
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .heading {
    margin: 5px;
    color: #90919b;
    font-size: 15px;
    font-weight: 400;
  }

  .description {
    font-size: 10px;
    margin: 0px 6px;
    color: #8f9099;
    font-weight: 400;
  }

  .header-description {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .switch-container {
    display: flex;
    align-items: center;
  }
  .switch-container-text-left {
    margin-right: 1rem;
    color: #90919b;
    font-size: 15px;
  }
  .switch-container-text-right {
    margin-left: 1rem;
    color: #90919b;
    font-size: 15px;
  }
}

.copy-unique-id {
  display: flex;
  align-items: center;
  margin: 6px 0;
  overflow: hidden;
  word-break: break-all;
  .unique-id {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b3e8b3;
    padding: 6px 8px;
    border-radius: 5px;
    font-size: small;
    height: 25px;
    font-family: monospace;
  }
  .copy-btn {
    background: #b3e8b3;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }
  .copy-btn:hover {
    transform: scale(1.1);
    transform-origin: center;
  }
}

@primary-color: #1DA57A;