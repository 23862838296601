.salary-break {
  font-size: 13px;
  line-height: 1.19;
  text-align: left;
}
.salary-break-size {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.19;
  text-align: left;
  color: @color-Denim;
}
.salary-container {
  border-radius: 10px;
  margin: 0px auto;
  margin-top: 25px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  .display-content-center();

  .manage-salary {
    width: 95%;
    flex: none;
    background-color: @color-White;
    padding: 30px;
    border-radius: 10px;

    @media (max-width: 1200px) {
      padding: 15px;
    }

    .graph-data {
      h2 {
        font-size: 30px;
        .font-style-left();
        line-height: 1.23;
        color: @color-Tundora;
      }
      @media (max-width: 600px) {
        font-size: 25px;
      }
      .displayflex-horizontal {
        .display-center-content-space-between();

        .ctc-data-container {
          margin: 20px 0;

          @media (max-width: 1200px) {
            margin: 10px 0;
          }

          .card-data-heading {
            font-size: 12px;
            color: @color-Manatee;
            font-weight: 400;
            margin-top: 20px;
          }

          .currency-format {
            font-size: 16px;
            color: @color-Manatee;
            font-weight: 500;
            margin-right: 16px;
          }

          .amount {
            font-size: 25px;
            font-weight: 700;
            @media (max-width: 600px) {
              font-size: 20px;
            }
          }
        }
      }
      .chart-container {
        margin: 1rem 0;
      }
      .info-container {
        margin: 2rem auto;
        padding: 9px;
        text-align: center;
        font-size: 12px;
        width: 80%;
        color: @color-Shamrock;
        background-color: @color-FrostedMint;
        border-radius: 5px;
      }
    }

    .break-down {
      height: auto;
      background-color: @color-AthensGrey;
      border-radius: 5px;
      margin-top: 10px;

      .break-down-section {
        padding: 30px;
      }
      .break-down-section-left {
        border-right: 0.2px solid @color-Mischka;
        @media (max-width: 600px) {
          border-right: none;
        }
      }

      .padding-breakdown {
        padding: 0 30px;
      }

      h3 {
        font-size: 15px;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: @color-SharkLite;
        margin: 10px 0;
      }

      .individual-structure {
        .display-center-content-space-between();
        width: 100%;
        padding: 10px 0;

        .break-label {
          .salary-break();
          font-weight: 500;
          color: @color-MidGray;
        }

        .break-value {
          .salary-break();
          color: @color-MidGray;
        }

        .break-label-earning {
          .salary-break();
          font-weight: 700;
          color: @color-Shamrock;
        }

        .break-value-earning {
          .salary-break();
          font-weight: 700;
          color: @color-Shamrock;
        }
        .break-label-deduction {
          .salary-break();
          font-weight: 700;
          color: @color-Froly;
        }

        .break-value-deduction {
          .salary-break();
          color: @color-Froly;
        }
      }
      .individual-structure-takehome {
        .display-center-content-space-between();
        width: 100%;
        height: 50px;
        padding: 10px 30px;
        border-top: 0.2px solid @color-Mischka;

        .break-label {
          .salary-break-size();
        }

        .break-value {
          .salary-break-size();
        }
      }
    }

    .slider {
      padding: 20px 30px 30px;
      border-radius: 5px;
      background-color: @color-AthensGrey;
      margin-bottom: 10px;

      @media (max-width: 1200px) {
        height: auto;
        width: 100%;
      }

      .slider-header {
        .display-center-content-space-between();
        width: 100%;
        margin-bottom: 20px;

        h3 {
          color: @color-Tundora;
          font-weight: 700;
          font-size: 25px;
          @media (max-width: 600px) {
            font-size: 20px;
          }
        }
      }

      .slider-container {
        .display-justify-content-space-between();
        width: 100%;
        height: 100%;
        padding: 0 10px;
        p {
          font-size: 12px;
          font-weight: bold;
          color: @color-Manatee;
        }
      }

      .ant-slider-rail {
        height: 8px !important;
      }

      .ant-slider-track {
        height: 8px !important;
      }
      .edit-container {
        .display-flex-column();
        justify-content: flex-start;
        align-items: flex-end;
        padding: 10px 0;
        width: 40%;
        height: 100%;
        .submit-container {
          width: 100%;
          display: flex;
        }
      }
    }
  }
}
