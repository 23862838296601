.anchor-lib {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  margin: 0px;
  font-weight: 300;
  letter-spacing: 0.75px;
  font-size: 15px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.anchor-type-8 {
  background-color: transparent;
  color: #feba01;
  font-weight: 300;
}

.anchor-type-8:hover,
.anchor-type-8:active {
  font-weight: 300;
  color: #feba01;
}

@primary-color: #1DA57A;