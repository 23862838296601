h1#PAN {
  color: rgb(1, 72, 138);
  text-decoration: underline;
}
h1#PAN:hover {
  color: rgb(104, 106, 224);
}

.credential-space-top {
  margin-top: 20px;
}

.credential-box {
  background-color: aliceblue;
  padding: 1.5rem;
  margin-top: 1rem;
  border-radius: 10px;
}

.m-document-box {
  padding: 10px;
  margin: 20px 0 0 0;
  border-radius: 5px;
}

.select-box{
  width: 150px;
  padding:5px 0;
  border-radius: 5px;
}

@primary-color: #1DA57A;