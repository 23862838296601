.drawer{
    h1{
        font-weight: 700;
        font-size: 18px;
        color: #000000;
    }
    span{
        color: #219653;
    }
    p{
        color: #00000099;
        
    }
    .flex-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .flex-col{
            display: flex;
            flex-direction: column;

        }
    }
}
@primary-color: #1DA57A;