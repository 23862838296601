.completion-message-container{
    min-height: 100vh;
    width: 100%;
    background: url('../../../assets/orgainsationOnboardingFinalMessageBackground.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .completion-message-container-message-container{
        background-color: #fff;
        width: 25vw;
        height: 25vw;
        border-radius: 10px;
        box-shadow: 0 1.5px 30px 0 rgba(145, 145, 145, 0.25);
        padding: 45px;

        .completion-message-heading{
            font-size: 40px;
            font-weight: 900;
            line-height: 55px;
        }

        .completion-message-para{
            color: #9898a5;
            margin-top: 24px;
            font-size: 15px;
        }

        .completion-message-button-container{
            margin-top: 150px;
            text-align: right;

            .completion-message-button{
                color: #fff;
                background-color: #40d2ac;
                border-radius: 10px;
                border: none;
                box-shadow: none;
                width: 5vw;
                height: 2vw;
                font-size: 16px;
            }
        }
    }
}
@primary-color: #1DA57A;