.site-layout-header {
  background: #f7f7fa;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      color: #232326;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      margin-left: 10px;
    }

    img {
      height: 25px;
      width: 25px;
    }
  }

  .logout-formm {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    .user-logo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0px;
    }

    .dropdown-logo-container {
      padding: 0px;
      border: none;
      background-color: #f0f2f5;
      height: 100%;
    }

    .name-container {
      margin: 0;
      margin-right: 24px;
      color: #73737b;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

@primary-color: #1DA57A;