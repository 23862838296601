.header{
    padding-left: 3rem;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.line{
    color: rgba(0,0, 0, 1);
    margin-bottom: 2rem;
    opacity: 30%;
}

h3{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

}

.app-white-card {
    .paper-header-container{
        h3{
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;

        }
    }
}

.user-details-container{
    .user-name-and-email{
        .user-name{
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
        }
        .user-email{
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.5);
        }
    }
}

.sub-list-email{
    .user-email{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;

    }
}

.date-of-joining{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;

}

.date-of-joining{
    .date-join{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;

    }
    .time-join{
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.5);
    }
}

span{
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

button.view-more{
    color: #405CD2;
    height: 19px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    
}

.mool-generated{
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #405CD2;
    span{
        padding-left: 8px;
        font-weight: 600;
        font-size: 16px;
        color: #405CD2;
    }
}

.drawer-email{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
}
.drawer-name{
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.drawer{
    h1{
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        padding:2rem 0rem 2rem 0rem;
    }
    td{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        padding: 0.5rem 2rem 0.5rem 0rem;
    }
}


@primary-color: #1DA57A;