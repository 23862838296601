.file-viewer-container{
    position: absolute;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    opacity: 0.90;
    background-color: black;
    
}

.file-viewer-container-1{
    width: 95vw;
    height: 100vh;
    position: absolute;
    z-index: 101;

    .top-bar{
        height: 7vh;
        position: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: #fff;
        font-weight: 900;
        letter-spacing: 1px;

        .top-bar-left{
            display: flex;
            align-items: center;
            .back-button-container{
                margin-right: 16px;
            }
    
            .file-type-container{
                margin-right: 16px;
                .file-type-background{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 5px;
                    font-weight: 900;
                    font-size: 14px;
                    color: #fff;
                }
    
                .file-type-pdf{
                    background-color: #f26b6b;
                }
    
                .file-type-jpg{
                    background-color : #6640d2;
                }
            }
    
            .file-name-container{
                
            }
        }

        .top-bar-right{
            .verified-text-container{
                margin-right: 5vw;
                font-size: 18px;
                font-weight: 400;
                .verified-logo-img{
                margin-right: 12px; 
                height: 22px;
                width: 22px;
                }
            }
        }

        
    }

    .document-display-area-zoom-container{
        overflow: auto;
        width: 90vw;
        height: 90vh;
        margin: 0px auto;

        .document-display-spin-container{
            position: relative;
            margin: auto;
            z-index: 101;
        }

        .document-display-area{
            height: 100%;
            object-fit: contain;
    
            .document-display{
                max-height: 100%;
                display: block;
                z-index: 101;
                object-fit: contain;
            }
        }
    }
}

.bottom-nav-panel-container{
    width: 95vw;
    position: absolute;
    top: 90vh;
    z-index: 101;

    .bottom-nav-panel{
        margin: 0px auto;
        z-index: 102;
        color: #fff;
        font-size: 16px;
        letter-spacing: 0.8px;
        background-color: #000;
        width: 400px;
        height: 65px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.document-display-area-zoom-container::-webkit-scrollbar {
    display: none;
  }
  
  .document-display-area-zoom-container {
    -ms-overflow-style: none; 
    scrollbar-width: none;  
  }
@primary-color: #1DA57A;