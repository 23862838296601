.register-container {
    font-family:'DM Sans', sans-serif;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: url('../../assets/register_background.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    opacity: 1;

    @media (max-width: 450px) {
        width: 100%;
        height: auto;
        padding: 10px;
    }

    .register-content {
        width: 30%;
        max-width: 430px;
        height: 750px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 60px #9191913F;
        border-radius: 15px;
        padding: 45px 45px 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 1200px) {
            padding: 35px;
            width: 50%;
        }

        @media (max-width: 450px) {
            padding: 20px;
            width: 100%;
        }

        .register-heading {
            text-align: left;
            font-weight: bold;
            font-size: 32px;
            letter-spacing: 0px;
            font-size: 2rem;
            color: #232326;
            opacity: 1;
        }

        .submit-container {
            margin: 50px 0px 10px 0px;

            .submit-button, .submit-button:active, .submit-button:focus, .submit-button:hover{
                margin: 0px;
            }

            .submit-button {
                background: #40D2AC 0% 0% no-repeat padding-box;
                border-radius: 10px;
                border: none;
                height: 40px;
                letter-spacing: 0px;
                color: #FFFFFF;
            }
        }
        
        .goto-login-container{
            width: 100%;

            .goto-login {
                height: 50px;
                border-top: 0.5px solid #EAEAEE;
                text-align: center;
                padding-top: 20px;
                color: #9898a5;
            }
        }
    }

    .register-name {
        width: 35%;
        text-align: left;
        font-size: 70px;
        font-weight: bold;
        font-size: 900;
        letter-spacing: 0px;
        color: #F3A02E;
        opacity: 1;
        @media  (max-width: 1200px) {
            display: none;
        }

    }
}

.register-content.label-float{
    padding: 8px;
}

.register-input.ant-input{
    width: 100%;
    border: none;
    border-bottom: 1.5px solid #EAEAEE;
    height: 50px;
    box-shadow: none;
    padding: 1px
}

.register-input.ant-input:focus
.register-input.ant-input:active{
    border-bottom: 2px solid red !important;
}
@primary-color: #1DA57A;