.manageAds-table-container {
  width: 98%;
  height: auto;
  .ads-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .table-header-right{
      .table-header{
        margin-right: 10px;
        color: #bcbdc8;
      }
      .new-campaign-button {
        color: #fea101;
        font-size: 14px;
      }
    }
  }
}

.numCard-container {
  margin-bottom: 2.5rem;
  .numCard-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .numCard-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 15px;
      .numCard-header {
        font-size: 17px;
        font-weight: 500;
        text-align: left;
        color: #90919b;
      }
      .numCard-amount {
        font-size: 23px;
        font-weight: 600;
        text-align: left;
        color: #232326;
      }
    }
  }
}

.targetedPeople {
  display: flex;
  .targetAudience {
    margin: 0px 2px;
    padding: 4px;
    color: #bcbdc8;
    border: 1px solid #bcbdc8;
    border-radius: 2.5px;
  }
}

.rejection-modal {
  min-width: 20vw;

  .rejection-modal-header {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 24px;
  }

  .rejection-text-box {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 16px;
  }

  .rejection-button {
    text-align: right;
  }
}

@primary-color: #1DA57A;