.active-client-management-card {
  width: 100%;
  height: 100%;
    .manageCreditContainer{
      width: 30%;
      display: flex;
      align-items: center;
      
      .credit-input-field {
        background-color: #f7f7fa;
        width: 55%;
      }
    }
}
.active-client-management-card{
  width: 100%;
  height: 100%;
  .app-flex-Logs-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .app-heading-items{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 25%;
      .credit-input-field {
        background-color: #f7f7fa;
        width: 65%;
      }
    }
    
  }
}

.org-list-name-container {
  display: flex;
  align-items: center;

  .org-list-name-and-pan {
    
    .org-list-name {
      font-size: 17px;
      font-weight: 800;
      margin-bottom: 6px;
    }

    .org-list-pan {
      font-size: 14px;
      font-weight: 500;
      color: #93979b;
    }
  }

  .org-list-logo{
      width: 45px;
      height: 45px;
      border-radius: 50px;
      background-color: #fff;
      margin-right: 24px;
  }

  .org-list-logo-color{
      background-color: #fff1de;
  }
}

.date-of-joining{
    color: #93979b;
    font-weight: 500;
}
.attachment{
    color: blue;
    font-weight: 500;
}
.credited{
  color:#40d2ac;
  font-weight: 500;
}
.rejected{
  color:#f26b6b;
  font-weight: 500;
}

.credit-modal{
  padding: 10px;
  margin-bottom: 6rem;
  border-radius: 10px;
  .modal-header-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .modal-section-heading{
      color: #151b30;
      font-size: 20px;
      font-weight: 700;
    }
    .modal-close{
      display: flex;
    }
  }
  .modal-body-section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .client-type{
      .client-type-heading{
        margin-top: 12px;
        font-size: 15px;
        text-align: left;
        color: #9898a5;
      }
    }
    .client-name{
      .client-type-heading{
        margin-top: 12px;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        color: #9898a5;
      }
    }
    .credit-addition-info{
      .credit-addition-heading{
        margin-top: 12px;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        color: #9898a5;
      }
    }
    .credit-button{
      text-align: right;
    }
  }
}

.edit-card-button{
  display: flex;
  align-items: center;
  color: #fea101;
}
@primary-color: #1DA57A;