.support-ticket-details-model-container{
    border-radius: 10px;
    .support-ticket-details-model{
        .support-ticket-details-model-buttons{
            text-align: right;
        }
        .support-ticket-modal-label{
            color:#9898a5;
            font-weight: 400;
            font-size: 16px;
        }
        .support-ticket-modal-text-1{
            color: #232326;
            font-weight: 600;
            font-size: 16px;
        }
        .support-ticket-modal-text-2{
            color: #2d2e30;
            font-weight: 16px;
            font-weight: 500;
        }
        .support-ticket-modal-select-field,
        .support-ticket-modal-select-option{
            color:#9898a5;
            font-weight: 400;
            font-size: 16px;
            width: 130px;
        }
    }
}
@primary-color: #1DA57A;