.login-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: url("../../assets/common/Mask Group 1.svg"),
      url("../../assets/common/Rectangle 3.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    opacity: 1;
  
    @media (max-width: 450px) {
      width: 100%;
      height: auto;
      padding: 10px;
    }
  
    .login-contianer {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      .login-form-card {
        position: relative;
        width: 30rem;
        height: 43rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 60px #9191913f;
        border-radius: 1rem;
        opacity: 1;
  
        .card-title-container {
          padding-left: 1rem;
          font-size: 1rem;
          font-weight: 400;
          height: 3.5rem;
          background: #405cd2 0% 0% no-repeat padding-box;
          border-radius: 15px 15px 0px 0px;
          opacity: 1;
          color: #ffffff;
  
          .card-title-text {
            position: absolute;
            padding-top: 3%;
  
            .card-title-icon {
              display: inline-block;
              padding-right: 1rem;
            }
          }
        }
  
        .card-text-holder {
          padding: 3rem;
          padding-bottom: 1rem;
  
          .card-text {
            font-weight: 700;
            font-size: 2rem;
          }
        }
  
        .login-info-holder {
          padding: 3rem;
          padding-top: 0px;
  
          .login-info-text {
            color: #9898a5;
            font-size: 1rem;
  
            .input-box {
              width: 100%;
              border-style: hidden;
              border-bottom: 0.01rem #9898a5 solid;
              margin-top: 0.5rem;
              outline: none;
              font-size: 1rem;
            }
          }
  
          .button {
            display: block;
            background-color: #40d2ac;
            border-color: #40d2ac;
            padding-left: 2rem;
            padding-right: 2rem;
            margin-top: 2.25rem;
            border-radius: 0.7rem;
          }
  
          .stayLoggedIn-checkbox {
            color: #9898a5;
            display: block;
            font-size: 1rem;
            margin-top: 1.25rem;
          }
        }
  
        .user-info-holder {
          display: flex;
          padding-left: 3rem;
          margin-bottom: 3rem;
          align-items: center;
  
          .employee-avatar {
            background-color: #ffbc8b;
            padding: 0.6rem;
            border-radius: 50%;
            width: 70%;
          }
  
          .organisation-avatar {
            background-color: #fff1de;
            padding: 0.8rem;
            border-radius: 50%;
            width: 100%;
          }
  
          .user-info {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: space-around;
  
            p {
              padding: 0px;
              margin: 0px;
            }
          }
  
          .userType {
            font-size: 0.8rem;
            color: #9898a5;
          }
  
          .userName {
            font-size: 1rem;
            color: #232326;
            font-weight: 500;
          }
        }
  
        .additional-links {
          padding: 3rem;
          width: 100%;
          position: absolute;
          bottom: 0px;
          font-size: 1rem;
          text-align: center;
  
          a {
            display: block;
            color: #9898a5;
            padding-top: 2rem;
          }
        }
  
        .additional-links-for-password {
          padding: 3rem;
          padding-bottom: 2rem;
          width: 100%;
          position: absolute;
          bottom: 0px;
          font-size: 1rem;
          text-align: left;
  
          .additional-links-for-password-hr{
            border: none;
            border-bottom: 1px solid #EAEAEE !important;
            margin-bottom: 2rem;
          }
  
          a {
            display: block;
            color: #405cd2;
            margin: 0px;
            margin-top: -0.5rem;
            margin-bottom: -0.5rem;
            padding: 0px;
            padding-bottom: 0.1rem;
          }
        }
      }
    }
  
    .hero-container {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      @media (max-width: 1100px) {
        display: none;
      }
  
  
      .skeleton1 {
        align-self: flex-end;
        padding-bottom: 1rem;
        width: 30%;
      }
  
      .heroImage {
        align-self: flex-end;
        padding: 1rem;
        padding-bottom: 5rem;
        width: 90%;
      }
  
      .skeleton2 {
        align-self: flex-start;
        padding: 1rem;
        width: 35%;
      }
    }
  }
  
  
  .input-login.ant-input,
  .input-login.ant-input-password,
  .input-login.ant-input-affix-wrapper{
    width: 100%;
    border: none;
    border-bottom: 1.5px #9898a5 solid;
    box-shadow: none;
    text-transform: uppercase;
    height: 32px;
    font-size: 17px;
    padding: 0px;
    margin-top: 16px;
  }
  
  
  
  .imput-login.ant-input-hover,
  .input-login.ant-input:focus,
  .input-login.ant-input-password:hover,
  .input-login.ant-input-password:focus,
  .input-login.ant-input-password:active,
  .input-login.ant-input-affix-wrapper:hover,
    .input-login.ant-input-affix-wrapper:focus{
    border-bottom: #405cd2 solid 2px !important;
  }
@primary-color: #1DA57A;